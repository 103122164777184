import * as types from "../mutation-types";

export default {
  state: {
    loadedTribe: {},
    chiefedOrgs: [],
    userTribe: {},
    receiptData: {},
    showLogin: false,
    loadedOrg: {},
    appContextOrg: {},
    hasOrg: false,
    addresses: [],
    selectedAddress: {},
  },
  getters: {
    chiefedOrgs: (state) => state.chiefedOrgs,
    userTribe: (state) => state.userTribe,
    loadedTribe: (state) => state.loadedTribe,
    receiptData: (state) => state.receiptData,
    showLogin: (state) => state.showLogin,
    loadedOrg: (state) => state.loadedOrg,
    appContextOrg: (state) => state.appContextOrg,
    hasOrg: (state) => state.hasOrg,
    addresses: (state) => state.addresses,
    selectedAddress: (state) => state.selectedAddress,
  },
  mutations: {
    [types.V_API.SET_SHOW_LOGIN](state, showLogin) {
      state.showLogin = showLogin;
    },
    [types.V_API.SET_CHIEFED_ORGS](state, chiefedOrgs) {
      if (chiefedOrgs.length > 0) {
        state.hasOrg = true;
      } else {
        state.hasorg = false;
      }
      state.chiefedOrgs = chiefedOrgs;
    },
    [types.V_API.SET_USER_TRIBE](state, user) {
      state.userTribe = user;
    },
    [types.V_API.SET_LOADED_TRIBE](state, loadedTribe) {
      state.loadedTribe = loadedTribe;
    },
    [types.V_API.SET_RECEIPT_DATA](state, receiptData) {
      state.receiptData = receiptData;
    },
    [types.V_API.SET_LOADED_ORG](state, org) {
      state.loadedOrg = org;
    },
    [types.V_API.SET_APP_CONTEXT_ORG](state, org) {
      state.appContextOrg = org;
    },
    [types.V_API.SET_ADDRESSES](state, addresses) {
      state.addresses = addresses;
    },
    [types.V_API.SET_SELECTED_ADDRESS](state, selectedAddress) {
      state.selectedAddress = selectedAddress;
    },
  },
  actions: {
    loadUser(ctx, UID) {
      UID = UID || vG("uid");
      vDo("core/get_user", { uid: vG("uid") }, (response) => {
        let user = response["data"];
        //  cart of user if there is one.
        let cart = user.meta ? user.meta.cart || {} : {};
        if (Object.keys(cart).length > 0) {
          ctx.commit(types.CART.SET_USER_CART, cart);
        } else {
          ctx.commit(types.CART.SET_CART_PRICES, 0);
          ctx.commit(types.CART.SET_CART_COUNT, 0);
          ctx.commit(types.CART.SET_USER_CART, cart);
        }

        let paymentIntent = user.meta ? user.meta.payment_intent || {} : {};
        if (Object.keys(paymentIntent).length > 0) {
          ctx.commit(types.STRIPE.SET_PAYMENT_INTENT, paymentIntent);
        }

        ctx.commit(types.V_API.SET_USER_TRIBE, user);
      });
    },
    clearAppContextOrg(ctx) {
      ctx.dispatch("putUser", {
        uid: vG("uid"),
        app_context_org: null,
      });
      ctx.commit(types.V_API.SET_APP_CONTEXT_ORG, {});
    },
    loadAppContextOrg(ctx, tribe) {
      ctx.dispatch("setLoading", true);

      if (ctx.state.appContextOrg.id) {
        ctx.dispatch("clearAddresses");
      }
      vDo("core/get", { tribe: tribe }, (response) => {
        ctx.commit(types.V_API.SET_APP_CONTEXT_ORG, response);
        if (
          ctx.state.userTribe.id &&
          ctx.state.userTribe["meta"]["app_context_org"] != tribe
        ) {
          ctx.dispatch("putUser", {
            uid: vG("uid"),
            app_context_org: tribe,
          });
        }
        ctx.dispatch("setLoading", false);
      });
    },
    putUser(ctx, userData) {
      ctx.dispatch("setLoading", true);

      vDo("core/put_user", userData, (response) => {
        ctx.dispatch("setLoading", false);
        ctx.dispatch("loadUser");
      });
    },
    loadTribe(ctx, tribe) {
      vDo("core/get", { tribe: tribe }, (response) => {
        ctx.commit(types.V_API.SET_LOADED_TRIBE, response);
      });
    },
    loadOrg(ctx, tribe) {
      vDo("core/get", { tribe: tribe }, (response) => {
        ctx.commit(types.V_API.SET_LOADED_ORG, response);
      });
    },
    clearLoadedOrg(ctx) {
      ctx.commit(types.V_API.SET_LOADED_ORG, {});
    },
    clearLoadedTribe(ctx) {
      ctx.commit(types.V_API.SET_LOADED_TRIBE, {});
    },
    putTribe(ctx, tribeData) {
      vDo("core/put", tribeData, (response) => {
        ctx.dispatch("loadTribe", tribeData["tribe"]);
      });
    },
    loadChiefedOrgs(ctx) {
      ctx.dispatch("checkAdminAccess");
      vDo("core/get_chiefed_orgs", { tribe: vG("uid") }, (response) => {
        ctx.commit(types.V_API.SET_CHIEFED_ORGS, response.data);
      });
    },
    claimReservedAssets(ctx, callBack) {
      vDo(
        "transfer/claim_reserved_assets",
        { tribe: vG("uid") },
        (response) => {
          if (response["x"] == 200) {
            ctx.dispatch("setSnackBarMessage", {
              message: `You received ${Object.keys(response.data).length} asset(s)!`,
              type: "SUCCESS",
            });
          }
          if (callBack) {
            callBack();
          }
        }
      );
    },
    loadReceiptData(ctx, txnObj) {
      vDo("core/get_txn", txnObj, (response) => {
        ctx.commit(types.V_API.SET_RECEIPT_DATA, response.data);
      });
    },
    showLoginPage(ctx, show) {
      ctx.commit(types.V_API.SET_SHOW_LOGIN, show);
    },
    loadAddresses(ctx, tribe) {
      if (!tribe) {
        tribe = vG("uid");
      }
      ctx.commit(types.V_API.SET_ADDRESSES, []);
      vDo("addresses/get_addresses", { tribe: tribe }, (response) => {
        ctx.commit(types.V_API.SET_ADDRESSES, response.data);
      });
    },
    clearAddresses(ctx) {
      ctx.commit(types.V_API.SET_ADDRESSES, {});
      ctx.commit(types.V_API.SET_SELECTED_ADDRESS, {});
    },
    setSelectedAddress(ctx, addressObj) {
      let address = addressObj["address"];
      let tribe = addressObj["tribe"];
      if (!tribe) {
        tribe = vG("uid");
      }
      if (address["default"] == "false") {
        vDo(
          "addresses/set_default_address",
          { tribe: tribe, address_id: address["id"] },
          (resonse) => {
            ctx.dispatch("loadAddresses", tribe);
          }
        );
      }
      ctx.commit(types.V_API.SET_SELECTED_ADDRESS, address);
    },
  },
};
